/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */

// LOGOUT
const logout = () => {
  // localStorage.clear();
  // window.location.reload();
};

export { logout };
